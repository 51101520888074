import React, { useEffect, useState, ReactNode, ReactText } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css'
// import '../../src/styles/rainbowbutton.css'
// import '../../src/styles/fee.css'
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Box,
    Center,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Button,
    Link,
    Divider,
    Flex,
    FlexProps,
    HStack,
    IconButton,
    Stack,
    VStack,
    useDisclosure,
    useMediaQuery
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

interface LinkItemProps {
    name: string;
    to: string;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'About Us', to: '/#/aboutus' },
    { name: 'Resources', to: '/#/resources' },
    { name: 'Schedule', to: '/#/schedule' }
];

export default function Registration() {

    const navigate = useNavigate()

    const [isMobile] = useMediaQuery('(max-width: 767px)');

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
        <Flex direction="column" minHeight="100vh">
            {isMobile ?
                <Flex style={{backgroundColor:"#074500"}}>
                    <VStack width="100%">
                        <HStack>
                            <Center>
                                <Button
                                    className="homebutton"
                                    style={{ fontSize: "20px", backgroundColor: "#074500", color: "#fff" }}
                                    onClick={() => navigate("/home")}
                                >SJAMUN</Button>
                            </Center>
                            <IconButton
                                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                                aria-label={'Open Menu'}
                                display={{ md: 'none' }}
                                onClick={isOpen ? onClose : onOpen}
                                style={{
                                    backgroundColor: "#074500",
                                    color: "#fff"
                                }}
                            />
                        </HStack>
                        {isOpen ? (
                            <Box pb={4} display={{ md: 'none' }}>
                                <Stack as={'nav'} spacing={4}>
                                    {LinkItems.map((link) => (
                                        <NavItem key={link.name} to={link.to}>
                                            {link.name}
                                        </NavItem>
                                    ))}
                                </Stack>
                            </Box>
                        ) : null}
                    </VStack>
                    {/* <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex> */}
                </Flex>
                :
                <Flex id="header">
                    <button className="homebutton" onClick={() => navigate("/home")}>SJAMUN</button>
                    <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex>
                </Flex>
            }
            {isMobile ?
                <div style={{ textAlign: "center" }}>
                    <h1 className="resourcesmob">Resources</h1>
                </div>
                :
                <div style={{ textAlign: "center" }}>
                    <h1 className="resources">Resources</h1>
                </div>
            }
            <Center>
                <Link
                    href='https://drive.google.com/drive/folders/1xJ0zF6VI_-fmh9ThQ175CaSTLBDe9yxN?usp=sharing'
                    target='_blank'
                >
                    <Button
                        backgroundColor="#ff7b1c"
                        _hover={{
                            backgroundColor: "#ff9c54",
                            color: "#fff"
                        }}
                        fontSize={['xs', 'md', 'lg']}
                        size={['xs', 'md', 'lg']}
                    >
                        View General Resources
                    </Button>
                </Link>
            </Center>
            <br />
            <Center flex="1">
                <Box className="committeetab">
                    <Tabs isFitted variant="enclosed">
                        <TabList>
                            <Tab><Flex fontSize={['xs', 'md', 'lg']}>MS</Flex></Tab>
                            <Tab><Flex fontSize={['xs', 'md', 'lg']}>HS</Flex></Tab>
                            <Tab><Flex fontSize={['xs', 'md', 'lg']}>MS+HS</Flex></Tab>
                            <Tab><Flex fontSize={['xs', 'md', 'lg']}>ICJ</Flex></Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Accordion>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    MS - A (UNODC)
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1COQ5XDKlYqgwOgsa_Bd7nOlySbDHZhN6Bvx9cbqt1Mg/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54",
                                                        color: "#fff"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    MS - B (UNEP)
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1nGHx65b53D5OmFMwq02BpuQCONnpBP_CVB_Ko_LtzKM/edit#heading=h.4pvs8m295kjw'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    MS - C (IAEA)
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1NTfA5rMrregArgA0CJMO-7utydeXM0HqFXbkWxLZBuU/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    MS - D (WHO)
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1T8gxo5Zsw-UQozf0fK0vJmPMZ0GY2ljnw9bQdSTSgIE/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    MS Double-Up (UNHCR)
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/17Rdh3TGmHRly2v0aDHwI6ScKvgEniCW1A0pr88-uIdc/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    MS Crisis
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1xbdN-sRhpHJInhtqjtBjrRfYT5CVxk9G4_VHhR9J-iA/edit#heading=h.4pvs8m295kjw'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </TabPanel>
                            <TabPanel>
                                <Accordion>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    HS - A (OPCW)
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1VnuyeQu_e6GXFAJFHDsWf0h4ZXzp6oEWnelfnLHzBZ8/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    UNSC
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1QAuPbqmO0yVI6WmEVOg-tFHtv-GkMvMvHec9rVDlpa0/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    HS Crisis
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1ooDjaiONw8js3nZ6hh0HY02W7cIq5dcXoj9tM0oN7U0/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </TabPanel>
                            <TabPanel>
                                <Accordion>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    DISEC
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1oIUKAhwu_CoyBKbVp7EscpzP9jSRmB19rn8qrKmsMsQ/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </TabPanel>
                            <TabPanel>
                                <Accordion>
                                    <AccordionItem>
                                        <h2>
                                            <AccordionButton>
                                                <Box as="span" flex='1' textAlign='left' fontSize={['xs', 'md', 'lg']}>
                                                    ICJ
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4} fontSize={['xs', 'md', 'lg']}>
                                            <Link
                                                href='https://docs.google.com/document/d/1LFKq6VAGFs2ySPM8SuwMPJmmcd7-h7G9JaDet_jGbGA/edit?usp=sharing'
                                                target='_blank'
                                            >
                                                <Button
                                                    backgroundColor="#ff7b1c"
                                                    _hover={{
                                                        backgroundColor: "#ff9c54"
                                                    }}
                                                    fontSize={['xs', 'md', 'lg']}
                                                    size={['xs', 'md', 'lg']}>
                                                    Go to Resources Google Drive
                                                </Button>
                                            </Link>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Center>
            <footer style={{ backgroundColor: "#074500", height: "80px", textAlign: "center", color: "#fff", fontWeight: "bold", paddingTop: "15px" }}>Copyrightⓒ2024 <a style={{ color: "#ff7b1c" }} href='https://github.com/qwertyuiop0011'>Jeesung Lee</a><br />All rights reserved.</footer>
            </Flex>
        </>
    );
}

interface NavItemProps extends FlexProps {
    children: ReactText;
    to: string;
}

const NavItem = ({ children, ...rest }: NavItemProps) => {
    return (
        <Link
            href={`${rest.to}`}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
        >
            <Flex
                align="center"
                // p="4"
                // mx="4"
                borderRadius="md"
                role="group"
                cursor="pointer"
                transitionDuration="0.4s"
                fontWeight="bold"
                {...rest}
            >
                <div className='homebutton' style={{ fontSize: "15px" }}>{children}</div>
            </Flex>
            <Divider />
        </Link>
    );
};
