import React, { useEffect, useState, ReactNode, ReactText } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css'
// import '../../src/styles/rainbowbutton.css'
// import '../../src/styles/fee.css'
import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    FlexProps,
    HStack,
    IconButton,
    Image, Stack, VStack, useDisclosure, useMediaQuery,
    Link
} from '@chakra-ui/react';
import sjamunGroupPhoto from '../images/sjamun_group_photo_2.jpg';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

interface LinkItemProps {
    name: string;
    to: string;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'About Us', to: '/#/aboutus' },
    { name: 'Resources', to: '/#/resources' },
    { name: 'Schedule', to: '/#/schedule' }
];

export default function Registration() {

    const navigate = useNavigate()

    const [isMobile] = useMediaQuery('(max-width: 767px)');

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {isMobile ?
                <Flex style={{backgroundColor:"#074500"}}>
                    <VStack width="100%">
                        <HStack>
                            <Center>
                                <Button
                                    className="homebutton"
                                    style={{ fontSize: "20px", backgroundColor: "#074500", color: "#fff" }}
                                    onClick={() => navigate("/home")}
                                >SJAMUN</Button>
                            </Center>
                            <IconButton
                                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                                aria-label={'Open Menu'}
                                display={{ md: 'none' }}
                                onClick={isOpen ? onClose : onOpen}
                                style={{
                                    backgroundColor: "#074500",
                                    color: "#fff"
                                }}
                            />
                        </HStack>
                        {isOpen ? (
                            <Box pb={4} display={{ md: 'none' }}>
                                <Stack as={'nav'} spacing={4}>
                                    {LinkItems.map((link) => (
                                        <NavItem key={link.name} to={link.to}>
                                            {link.name}
                                        </NavItem>
                                    ))}
                                </Stack>
                            </Box>
                        ) : null}
                    </VStack>
                    {/* <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex> */}
                </Flex>
                :
                <Flex id="header">
                    <button className="homebutton" onClick={() => navigate("/home")}>SJAMUN</button>
                    <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex>
                </Flex>
            }
            {isMobile ?
                <div>
                    <center>
                        <Box height="100px" />
                        <Image
                            src={sjamunGroupPhoto}
                            alt='group'
                            width='90%'
                            borderRadius='10px'
                        />
                    </center>
                </div>
                :
                <div>
                    <center>
                        <Image
                            margin="20px"
                            src={sjamunGroupPhoto}
                            alt='group'
                            width='90vh'
                            borderRadius='20px'
                        />
                    </center>
                </div>
            }
            <div>
                <p className="intro" style={{ fontSize: "15px", color: "black", fontWeight: "bold" }}>
                    Greetings everyone,
                    <br/><br/>
                    This is Dong Yoon Lee, and I will serve as Secretary-General for the upcoming SJAMUN II.
                    <br/><br/>
                    I have been involved in the Model United Nations program since early middle school. During my seven-year journey with MUN, I have witnessed and experienced that MUN offers us something beyond learning: It provides a space where we can learn how to communicate with others, how to view world issues from different perspectives, and how to formulate effective solutions. But essentially, and most importantly, it serves as an opportunity to build relationships and create lasting memories.
                    <br/><br/>
                    With a passion for organizing more of these opportunities, our secretariats, chairs, and faculty members have been dedicating their efforts to the second iteration of SJAMUN.
                    <br/><br/>
                    I sincerely hope you will take something invaluable away from this conference, and the best way to do this is to challenge yourself. Secretariats, chairs, staff, and advisors will always be around to support you.
                    <br/><br/>
                    See you soon in early October, during the beautiful season of Jeju Autumn.
                    <br/><br/>
                    Best regards,
                    <br/><br/>
                    Dong Yoon (Daniel) Lee<br/>
                    Secretary-General of SJAMUN II<br/>
                    President of SJA Model United Nations Club<br/>
                    On behalf of the SJA MUN secretariats and faculty

                </p>
            </div>
            <footer style={{
                backgroundColor: "#074500",
                height: "70px",
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
                paddingTop: "15px",
                position: "relative",
                bottom: "0"
            }}
            >Copyrightⓒ2024 <a style={{ color: "#ff7b1c" }} href='https://github.com/qwertyuiop0011'>Jeesung Lee</a><br />All rights reserved.</footer>
        </>
    );
}


interface NavItemProps extends FlexProps {
    children: ReactText;
    to: string;
}

const NavItem = ({ children, ...rest }: NavItemProps) => {
    return (
        <Link
            href={`${rest.to}`}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
        >
            <Flex
                align="center"
                // p="4"
                // mx="4"
                borderRadius="md"
                role="group"
                cursor="pointer"
                transitionDuration="0.4s"
                fontWeight="bold"
                {...rest}
            >
                <div className='homebutton' style={{ fontSize: "15px"}}>{children}</div>
            </Flex>
            <Divider />
        </Link>
    );
};
