import React, { useEffect, useState, ReactNode, ReactText } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css'
import { Box, Button, Center, Divider, Flex, FlexProps, HStack, IconButton, Link, Stack, VStack, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import sjamunSchedule from '../images/sjamun_schedule_2.png';

interface LinkItemProps {
    name: string;
    to: string;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'About Us', to: '/#/aboutus' },
    { name: 'Resources', to: '/#/resources' },
    { name: 'Schedule', to: '/#/schedule' }
];

export default function Home() {

    const navigate = useNavigate()

    const [isMobile] = useMediaQuery('(max-width: 767px)');

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Flex direction="column" minHeight="100vh">
            {isMobile ?
                <Flex style={{backgroundColor:"#074500"}}>
                    <VStack width="100%">
                        <HStack>
                            <Center>
                                <Button
                                    className="homebutton"
                                    style={{ fontSize: "20px", backgroundColor: "#074500", color:"#fff" }}
                                    onClick={() => navigate("/home")}
                                >SJAMUN</Button>
                            </Center>
                            <IconButton
                                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                                aria-label={'Open Menu'}
                                display={{ md: 'none' }}
                                onClick={isOpen ? onClose : onOpen}
                                style={{
                                    backgroundColor: "#074500",
                                    color:"#fff"
                                }}
                            />
                        </HStack>
                        {isOpen ? (
                            <Box pb={4} display={{ md: 'none' }}>
                                <Stack as={'nav'} spacing={4}>
                                    {LinkItems.map((link) => (
                                        <NavItem key={link.name} to={link.to}>
                                            {link.name}
                                        </NavItem>
                                    ))}
                                </Stack>
                            </Box>
                        ) : null}
                    </VStack>
                    {/* <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex> */}
                </Flex>
                :
                <Flex id="header">
                    <button className="homebutton" onClick={() => navigate("/home")}>SJAMUN</button>
                    <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex>
                </Flex>
            }
            <Center padding="0px" flex="1">
                <Box height="200px" />
                <Flex fontSize={['2xl', '3xl', '5xl']} padding="0px">
                    <p className='nf'>Schedule</p>
                </Flex>
            </Center>
            {isMobile ?
                <center>
                    <img
                        src={sjamunSchedule}
                        alt='group'
                        width='90%'
                    />
                    <Box height="50px"/>
                </center>
                :
                <center>
                    <img
                        src={sjamunSchedule}
                        alt='group'
                        width='50%'
                    />
                    <Box height="50px"/>
                </center>
            }
            <footer style={{ backgroundColor: "#074500", height: "80px", textAlign: "center", color: "#fff", fontWeight: "bold", paddingTop: "15px" }}>Copyrightⓒ2024 <a style={{ color: "#ff7b1c" }} href='https://github.com/qwertyuiop0011'>Jeesung Lee</a><br />All rights reserved.</footer>
            </Flex>
        </>
    );
}

interface NavItemProps extends FlexProps {
    children: ReactText;
    to: string;
}

const NavItem = ({ children, ...rest }: NavItemProps) => {
    return (
        <Link
            href={`${rest.to}`}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
        >
            <Flex
                align="center"
                // p="4"
                // mx="4"
                borderRadius="md"
                role="group"
                cursor="pointer"
                transitionDuration="0.4s"
                fontWeight="bold"
                {...rest}
            >
                <div className='homebutton' style={{ fontSize: "15px" }}>{children}</div>
            </Flex>
            <Divider />
        </Link>
    );
};

