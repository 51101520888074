import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, HashRouter } from 'react-router-dom';
import Home from './components/home'
import Aboutus from './components/aboutus';
import Resources from './components/resources';
import Schedule from './components/schedule';
import NotFound from './components/404';

export const App = () => (
  <HashRouter>
    <Routes>
      <Route path="/" element={<Navigate to="home" />} />
      <Route path="home" element={<Home />} />
      <Route path="aboutus" element={<Aboutus />} />
      <Route path="resources" element={<Resources />} />
      <Route path="schedule" element={<Schedule />} />
      <Route path="404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="404" />} />
    </Routes>
  </HashRouter>
);