import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css'
import {
    Flex,
    Text,
    Center,
    Box,
    useMediaQuery,
    useDisclosure,
    IconButton,
    FlexProps,
    Link,
    Stack,
    VStack,
    HStack,
    Button,
    Divider
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { IconType } from 'react-icons';
import { ReactText } from 'react';

interface LinkItemProps {
    name: string;
    to: string;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'About Us', to: '/#/aboutus' },
    { name: 'Resources', to: '/#/resources' },
    { name: 'Schedule', to: '/#/schedule' }
];

export default function NotFound() {

    const navigate = useNavigate()

    const [isMobile] = useMediaQuery('(max-width: 767px)');

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {isMobile ?
                <Flex>
                    <VStack width="100%">
                        <HStack>
                            <Center>
                                <Button
                                    className="homebutton"
                                    style={{ fontSize: "20px", backgroundColor: "transparent", color:"#fff" }}
                                    onClick={() => navigate("/home")}
                                >SJAMUN</Button>
                            </Center>
                            <IconButton
                                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                                aria-label={'Open Menu'}
                                display={{ md: 'none' }}
                                onClick={isOpen ? onClose : onOpen}
                                style={{
                                    backgroundColor:"transparent",
                                    color:"#fff"
                                }}
                            />
                        </HStack>
                        {isOpen ? (
                            <Box pb={4} display={{ md: 'none' }}>
                                <Stack as={'nav'} spacing={4}>
                                    {LinkItems.map((link) => (
                                        <NavItem key={link.name} to={link.to}>
                                            {link.name}
                                        </NavItem>
                                    ))}
                                </Stack>
                            </Box>
                        ) : null}
                    </VStack>
                    {/* <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex> */}
                </Flex>
                :
                <Flex id="header">
                    <button className="homebutton" onClick={() => navigate("/home")}>SJAMUN</button>
                    <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex>
                </Flex>

            }
            <Center>
                <VStack>
                <Flex style={{ backgroundColor: "#074500", height: "100%", textAlign: "center" }}>
                    <Text
                        className='nf'
                        fontSize={['3xl', '4xl', '6xl']}
                        style={{padding:"0px", paddingTop:"100px"}}
                    >There's nothing here...</Text>
                </Flex>
                <Button
                    backgroundColor="#ff7b1c"
                    _hover={{
                        backgroundColor: "#ff9c54",
                        color:"#fff"
                    }}
                    fontSize={['xs', 'md', 'lg']}
                    size={['xs', 'md', 'lg']}
                    onClick={() => navigate('/home')}
                >
                    Return to Home
                </Button>
                </VStack>
            </Center>
        </>
    );
}

interface NavItemProps extends FlexProps {
    children: ReactText;
    to: string;
}

const NavItem = ({ children, ...rest }: NavItemProps) => {
    return (
        <Link
            href={`${rest.to}`}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
        >
            <Flex
                align="center"
                // p="4"
                // mx="4"
                borderRadius="md"
                role="group"
                cursor="pointer"
                transitionDuration="0.4s"
                fontWeight="bold"
                {...rest}
            >
                <div className='homebutton' style={{fontSize:"15px"}}>{children}</div>
            </Flex>
            <Divider />
        </Link>
    );
};
