import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/main.css'
import {
    Flex,
    Text,
    Center,
    Box,
    useMediaQuery,
    useDisclosure,
    IconButton,
    FlexProps,
    Link,
    Stack,
    VStack,
    HStack,
    Button,
    Divider
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { IconType } from 'react-icons';
import { ReactText } from 'react';

interface LinkItemProps {
    name: string;
    to: string;
}

const LinkItems: Array<LinkItemProps> = [
    { name: 'About Us', to: '/#/aboutus' },
    { name: 'Resources', to: '/#/resources' },
    { name: 'Schedule', to: '/#/schedule' }
];

export default function Home() {

    const navigate = useNavigate()

    const [isMobile] = useMediaQuery('(max-width: 767px)');

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Flex direction="column" minHeight="100vh">
                {isMobile ?
                    <Flex>
                        <VStack width="100%">
                            <HStack>
                                <Center>
                                    <Button
                                        className="homebutton"
                                        style={{ fontSize: "20px", backgroundColor: "transparent", color: "#fff" }}
                                        onClick={() => navigate("/home")}
                                    >SJAMUN</Button>
                                </Center>
                                <IconButton
                                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                                    aria-label={'Open Menu'}
                                    display={{ md: 'none' }}
                                    onClick={isOpen ? onClose : onOpen}
                                    style={{
                                        backgroundColor: "transparent",
                                        color: "#fff"
                                    }}
                                />
                            </HStack>
                            {isOpen ? (
                                <Box pb={4} display={{ md: 'none' }}>
                                    <Stack as={'nav'} spacing={4}>
                                        {LinkItems.map((link) => (
                                            <NavItem key={link.name} to={link.to}>
                                                {link.name}
                                            </NavItem>
                                        ))}
                                    </Stack>
                                </Box>
                            ) : null}
                        </VStack>
                        {/* <Flex>
                        <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                        <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                        <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                    </Flex> */}
                    </Flex>
                    :
                    <Flex id="header">
                        <button className="homebutton" onClick={() => navigate("/home")}>SJAMUN</button>
                        <Flex>
                            <button className="navbutton" onClick={() => navigate("/aboutus")}>About Us</button>
                            <button className="navbutton" onClick={() => navigate("/resources")}>Resources</button>
                            <button className="navbutton" onClick={() => navigate("/schedule")}>Schedule</button>
                        </Flex>
                    </Flex>

                }
                <Center flex="1"> {/* Centered content takes up the remaining space */}
                    <div className="video-container">
                        <iframe
                            className="bg-video"
                            src="https://www.youtube.com/embed/XfEJgaBwC7E?autoplay=1&mute=1&controls=0&loop=1&playlist=XfEJgaBwC7E"
                            allowFullScreen
                            allow="autoplay"
                        />
                    </div>
                </Center>

                <footer style={{ backgroundColor: "#074500", color: "#fff", textAlign: "center", padding: "10px", position: "relative", height: "100px" }}>
                    <Text
                        className="greeting"
                        fontSize={['3xl', '4xl', '6xl']}
                    >
                        10.12.24 - 10.13.24
                    </Text>
                </footer>
            </Flex>
        </>
    );
}

interface NavItemProps extends FlexProps {
    children: ReactText;
    to: string;
}

const NavItem = ({ children, ...rest }: NavItemProps) => {
    return (
        <Link
            href={`${rest.to}`}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
        >
            <Flex
                align="center"
                // p="4"
                // mx="4"
                borderRadius="md"
                role="group"
                cursor="pointer"
                transitionDuration="0.4s"
                fontWeight="bold"
                {...rest}
            >
                <div className='homebutton' style={{ fontSize: "15px" }}>{children}</div>
            </Flex>
            <Divider />
        </Link>
    );
};
